import React from 'react';

import ShelbyFooter from 'src/components/organism/ShelbyFooter/ShelbyFooter';

type StateFarmPageTemplateProps = {
  children: React.ReactNode;
  className?: string;
  showFooterDisclaimer?: boolean;
};

const StateFarmPageTemplate = ({ children, className, showFooterDisclaimer = true }: StateFarmPageTemplateProps) => {
  return (
    <div className={`container container_center ${className}`}>
      {children}
      <ShelbyFooter showDisclaimer={showFooterDisclaimer} />
    </div>
  );
};

export default StateFarmPageTemplate;
