import React from 'react';
import StateFarmPageTemplate from 'src/components/templates/StateFarmPageTemplate/StateFarmPageTemplate';
import { Translate } from 'src/components/Translate';

const StateFarmUnauthorized = () => {
  return (
    <StateFarmPageTemplate>
      <div className="status-info" data-cy="terms-conditions-unauthorised">
        <Translate as="h2" resourceKey="agent-unauthorized.title" />
        <Translate as="p" className="terms-conditions--unauthorised" resourceKey="agent.nonPrincipal.unauthorized" />
      </div>
    </StateFarmPageTemplate>
  );
};

export default StateFarmUnauthorized;
