import React from 'react';
import { Helmet } from 'react-helmet';

import StateFarmUnauthorized from 'src/components/StateFarmUnauthorized/StateFarmUnauthorized';
import { useTracking } from 'src/components/TrackingContext';

const metadata = {
  title: 'Page View Unauthorized | HDC for State Farm',
};

const AgentUnauthorized = () => {
  useTracking('sf_agent_unauthorized_view');

  return (
    <>
      <Helmet title={metadata.title} />
      <StateFarmUnauthorized />
    </>
  );
};

export default AgentUnauthorized;
