import React from 'react';
import { Translate } from 'src/components/Translate';
import StateFarmLogo from 'src/components/agents/StateFarmLogo';
import DigiCertLogo from '../../agents/DigiCertLogo';

import './ShelbyFooter.scss';

type Props = {
  showDisclaimer?: boolean;
};

const footer = {
  copyrightLabel: 'footer.copyrightLabel',
  disclaimer: 'state-farm.disclaimer.footer',
  footerNavigation: {
    privacyPolicy: 'footer.footerNavigation.privacyPolicy',
    siteHelp: 'footer.footerNavigation.siteHelp',
  },
} as const;

export default function ShelbyFooter({ showDisclaimer = true }: Props) {
  return (
    <>
      {showDisclaimer && (
        <>
          <div className="inset-l" />
          <Translate
            className="shelby-footer-disclaimer max-width_content state-farm-disclaimer"
            resourceKey={footer.disclaimer}
            as="p"
          />
        </>
      )}

      <div className="inset-l logo-separator" />
      <div className="inset-l" />
      <StateFarmLogo />

      <footer className="shelby-footer">
        <div className="shelby-footer__inner">
          <span className="copyright-label">
            <Translate resourceKey={footer.copyrightLabel} />
          </span>
          <div className="footer-navigation__nav-links">
            <nav>
              <ul className="shelby-nav-items">
                <li>
                  <a href="https://www.hagerty.com/corporate/privacy-policy">
                    <Translate resourceKey={footer.footerNavigation.privacyPolicy} />
                  </a>
                </li>
                <li id="cobrowse-link">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.stopPropagation();
                      return false;
                    }}
                  >
                    <Translate resourceKey={footer.footerNavigation.siteHelp} />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <DigiCertLogo />
        </div>
      </footer>
    </>
  );
}
